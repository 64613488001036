import User from "../models/User";
import EntityManager from './EntityManager';
import NotificationService from "./NotificationService";

class SessionService {

  private cookieName: string = "eury";
  public user: User | undefined;
  public deviceToken: string;

  public storeToken(token: string) {
    this.setCookie(this.cookieName, token, 30);
  }

  logged() {
    return !!this.user;
  }

  initSession(user) {
    if (user) {
      this.user = new User(user);
      // localStorage.setItem("deliveryAddresses", JSON.stringify(this.user.deliveryAddresses));
    }
  }

  async initDevice() {
    let token = await NotificationService.getToken();
    this.deviceToken = token;
    if (token) {
      // Vérifier si deviceTokens est un tableau, sinon le convertir
      if (!Array.isArray(this.user.deviceTokens)) {
        // Convertir l'objet en tableau
        this.user.deviceTokens = Object.values(this.user.deviceTokens || {});
      }
    
      // Ajouter le token uniquement s'il n'existe pas déjà
      if (!this.user.deviceTokens.includes(token)) {
        this.user.deviceTokens.push(token);
        await EntityManager.update(this.user, { only: ["deviceTokens"] });
      }
    }    
  }

  public getUserId = () => this.user?.id;

  public setUser(user: User) {
    this.user = user;
  }


  public hasToken() {
    return this.getToken() !== null;
  }

  public getToken(): string {
    return this.getCookie(this.cookieName);
  }

  public isAuthenticated(): boolean {
    return !!this.user;
  }

  public isAuthorized(role: any): boolean {
    return this.isAuthenticated() && (role === "all" || this.user.type === role)
  }

  public safeStringify(object): string {
    var cache = [];
    let result = JSON.stringify(object, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.includes(value)) return;
        cache.push(value);
      }
      return value;
    });
    cache = null;
    return result;
  }

  public storeUser(user: User) {
    localStorage.setItem("user", this.safeStringify(user));
  }

  public deleteToken(): void {
    this.eraseCookie(this.cookieName)
    this.user = undefined;
  }

  public clear() {
    localStorage.clear();
    this.eraseCookie(this.cookieName)
  }

  private setCookie(name: string, value: string, days: number): void {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  private getCookie(name: string) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  private eraseCookie(name: string) {
    document.cookie = name+'=; Max-Age=-99999999;';
  }

}

export default new SessionService();
